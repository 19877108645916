import React, { useState } from 'react';

import { inject, observer } from 'mobx-react'

import { THEME } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { getRemoteConfigValue } from '@utils/firebase'

import NavbarH5 from './NavbarH5';
import NavbarWeb from './NavbarWeb';

function T (props) {
  const { type = '2', theme = THEME.HIGHLIGHT, hideProfile } = props;
  const [firebasedata, setData] = useState({
    guideist: [],
    postActiveList: []
  })
  const [topSearchType, settopSearchType] = useState('1')
  const { guideist = [], postActiveList = [] } = firebasedata

  useIsomorphicLayoutEffect(() => {
    const firbaseData = {}
    Promise.all([getRemoteConfigValue('beginner_guide'),
      getRemoteConfigValue('home_post_active')]).then(res => {
      try {
        firbaseData.guideist = JSON.parse(res[0]?._value || '[]')
        firbaseData.postActiveList = JSON.parse(res[1]?._value || '[]')
      } catch (error) {
        console.log(error)
      }
      setData(firbaseData)
    })
    // 处理屏幕过窄时候搜索框显示不下
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ inlineSize }] } = entry;
      let topSearchType
      // // 大屏pc
      if (inlineSize > 1300) {
        topSearchType = '1'
      }
      // 小屏幕pc
      if (inlineSize < 1300 && inlineSize > 960) {
        topSearchType = '2'
      }
      // // h5
      if (inlineSize < 960) {
        topSearchType = '3'
      }
      settopSearchType(topSearchType)
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body)
    }
  }, [])

  return (
    <>
    <NavbarH5 key='h5'
      guideist={guideist}
      postActiveList={postActiveList}
      topSearchType={topSearchType}
      theme={theme}
      hideProfile={hideProfile}
    />
    <NavbarWeb key='web'
      guideist={guideist}
      postActiveList={postActiveList}
      topSearchType={topSearchType}
      type={type}
      theme={theme}
      hideProfile={hideProfile}
    />
    </>
  );
}

export default inject()(
  observer(T)
)
