/* eslint-disable @next/next/no-html-link-for-pages */
import React, { useState } from 'react';

import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import { THEME } from '@constants/index';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { getRate } from '@utils/getRate'

import { KikiLogo, NavbarWebLeft, NavbarWebRight } from './component/index'
import SearchDrawer from './component/SearchDrawer';

import NavBarStyles from './navbar.module.less';

export default inject(rootStore => ({
  userStore: rootStore.store.userStore,
  tradeStore: rootStore.store.tradeStore,
  marketStore: rootStore.store.marketStore
}))(
  observer(props => {
    const {
      userStore: { socialUserInfo: { avatar }, showAssetAmount, isLogin: initisLogin },
      tradeStore: { allAccountValue },
      type = '2', // type为1时登陆页面，其余为2
      // loginPath = LOGIN_BEFORE_PATHS.LOGIN,
      // registerPath = LOGIN_BEFORE_PATHS.REGISTER,
      topSearchType,
      theme = THEME.HIGHLIGHT,
      hideProfile
    } = props
    const [drawerVisible, setdrawerVisible] = useState(false);
    const [isLogin, setisLogin] = useState(false);

    useIsomorphicLayoutEffect(() => {
      setisLogin(initisLogin)
    }, [initisLogin])

    useIsomorphicLayoutEffect(() => {
      setdrawerVisible(false)
    }, [topSearchType])
    const searchDrawerVisabledChange = () => {
      setdrawerVisible(!drawerVisible)
    }

    const initBaseValue = async () => {
      await props.tradeStore.fetchMain()
      const marketSymbols = await props.marketStore.fetchMarketSymbol()
      props.tradeStore.updatePropertyDate({ marketSymbols, rate: getRate(), coinDetail: props.marketStore.coinDetail })
    }

    useIsomorphicLayoutEffect(() => {
      if (isLogin) {
        initBaseValue()
        // getDepositStatus()
      }
    }, [isLogin])
    const propertyClick = () => {
      props.userStore.updateShowAssetAmount(!showAssetAmount);
    }
    return <>
      {
        <div className={cn(NavBarStyles.header, NavBarStyles.padding2432, {
          [NavBarStyles.padding24]: (isLogin && type === '1') || type === '2',
          [NavBarStyles.background]: (isLogin && type !== '1') || type === '2'
        })}
          data-name={'kiki-navabar-web'}
        >
          <KikiLogo theme={theme} width={theme === 'dark' ? '66px' : '73px'} height={theme === 'dark' ? '24px' : '26px'}/>
          {
            type === '2' && !hideProfile && (
              <NavbarWebLeft />
            )
          }
              <NavbarWebRight
                  type={type}
                  topSearchType={topSearchType}
                  searchDrawerVisabledChange={searchDrawerVisabledChange}
                  theme={theme}
                  isLogin={isLogin}
                  propertyClick={propertyClick}
                  showAssetAmount={showAssetAmount}
                  allAccountValue={allAccountValue}
                  avatar={avatar}
                  hideProfile={hideProfile}
              />
              {
                hideProfile ? null : (
                  <SearchDrawer
                  visible={drawerVisible}
                  onClose={searchDrawerVisabledChange}
                  topSearchType={topSearchType}
                />
                )
              }
         </div>
      }
    </>;
  })
)
