import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native';
import FastImage from 'react-native-fast-image';

import p from '../utils/Transfrom';
import I18n from '../utils/i18n';

export default class EmptyComponent extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const { desc, source, onPress, textStyle, emptyImageStyle = {} } = this.props;

    return (

      <View
        style={[{
          flex: 1,
          justifyContent: 'center'
        }, this.props.style]}>
        <FastImage
          source={source || require('../images/nodata.webp')}
          style={StyleSheet.flatten([{ width: p(420), height: p(420) }, (emptyImageStyle)])}
        />
        {
          onPress
            ? (
              <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
                <Text
                  style={StyleSheet.flatten([{
                    color: '#696F7F',
                    fontSize: p(28),
                    fontWeight: 'bold',
                    marginTop: p(100)
                  }, (textStyle)])}
                >
                  {desc || I18n.t('noData')}
                </Text>
              </TouchableOpacity>
              )
            : (
              <Text
                style={StyleSheet.flatten([{
                  color: '#696F7F',
                  fontSize: p(28),
                  fontWeight: 'bold',
                  marginTop: p(100)
                }, (textStyle)])}
              >
                {desc || I18n.t('noData')}
              </Text>
              )
        }
      </View>

    );
  }
}
